<template>
  <div class="home">
    <!-- <img src="../assets/zhounianqing.png" alt="" class="img"> -->
    <!-- <div class="open" @click="openwx()"> -->
    <!-- <img src="../assets/open.png" alt="" class="ico"><span class="text">打开微信小程序</span> -->
    <!-- </div> -->
    <img
      src="https://web.xinyifm.cn/oss/xc-mini/tomini-mid.png"
      @click="openwx()"
      alt=""
    />
  </div>
</template>

<script>
// @ is an alias to /src
// import { Dialog } from 'vant';
import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import md5 from "js-md5";
export default {
  name: "HomeView",
  components: {
    // [Dialog.Component.name]: Dialog.Component,
  },
  data() {
    return {
      url: "",
    };
  },
  created() {},
  mounted() {
    this.openwx();
  },
  methods: {
    is_weixin() {
      if (/(micromessenger)/i.test(navigator.userAgent)) {
        return true;
      } else {
        return true;
      }
    },
    //点击按钮打开微信小程序
    openwx() {
      // 创建axios实例
      const service = axios.create({
        baseURL: "", // api 的 base_url
        timeout: 15000, // 请求超时时间
        method: "post",
        headers: {
          servername: "SilkwormMarketing",
          methodname: "SilkwormMobileMarketingService.RecallOldUserEvent",
        },
      });
      service.interceptors.request.use(async (config) => {
        //let r = Math.floor(Math.random() * 100000) + 1;
        const uuid = uuidv4().split("-").join("");
        const silkIdStr = "0";
        let r =
          uuid.substring(0, 4) +
          silkIdStr +
          uuid.substring(4, 4 + 16 - silkIdStr.length - 4);
        let t = new Date().getTime();
        let k = md5(
          md5(
            (
              config.headers.servername +
              "." +
              config.headers.methodname
            ).toLowerCase()
          ) +
            t +
            r
        );
        config.headers["X-Ashe"] = k;
        config.headers["X-Nami"] = r;
        config.headers["X-Garen"] = t;
        config.headers["X-Platform"] = "h5";
        config.headers["x-Annie"] = "XC";
        config.headers["x-Teemo"] = 0; // 用户silk id
        config.headers["x-Vayne"] = 0;
        config.headers["x-Version"] = "3.4.5";
        config.headers["x-City"] = 0;
        if (window.location.href.includes('test')) {
          config.headers["env"] = 'test';
        }
      
        return config;
      });
      let params={}
      // console.log(this.$route);
      if (this.$route.query.t == 1) {
        params.query="hchh=1"
      }
      service
        .post("https://gw.xiaocantech.com/rpc",params)
        .then((res) => {
          this.url = res.data.url_link;
          // console.log(this.url);
          window.location.href = this.url;
        });
    },
  },
};
</script>
<style scoped>
img {
  width: 100%;
  /* height: 100vh; */
}
</style>
